import { MEDIA_URL } from '@/constants/env';
import { fadeIn } from '@/constants/motions';
import Image from '@/ui/Image';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { motion } from 'framer-motion';

export default function HomeSliderBlock() {
  const slides = [
    {
      image: MEDIA_URL.concat('/slider-1.png'),
    },
    {
      image: MEDIA_URL.concat('/slider-2.png'),
    },
  ];
  return (
    <motion.section
      className="scroll-mt-40 lg:scroll-mt-20 xl:px-4"
      id="home"
      initial="hidden"
      transition={fadeIn.transition}
      variants={fadeIn}
      viewport={fadeIn.viewport}
      whileInView="visible"
    >
      <div className="mx-auto max-w-[1350px]">
        <Splide
          options={{
            arrows: false,
            autoplay: true,
            interval: 6000,
            rewind: 'true',
            speed: 2000,
            type: 'fade',
            classes: {
              pagination: 'splide__pagination md:!justify-end md:flex-row md:inset-0 md:!items-end',
            },
          }}
        >
          {slides.map(({ image }, i) => (
            <SplideSlide
              key={i}
              className="w-screen-sm h-80 overflow-hidden md:h-96 lg:h-[80vh] xl:rounded-xl"
            >
              <Image alt="Slide" className="h-full w-full object-cover" src={image} />
              <div className="absolute inset-0 flex h-full w-full items-center justify-start">
                <div className="ml-4 flex flex-col gap-2 text-white xl:ml-20">
                  <h1 className="text-3xl font-bold drop-shadow-lg lg:text-5xl">Suriname</h1>
                  <h1 className="text-3xl font-bold drop-shadow-lg lg:text-5xl">Natural</h1>
                  <h1 className="text-3xl font-bold drop-shadow-lg lg:text-5xl">Stone CO. NV</h1>
                  <p className="mt-2 text-base font-light drop-shadow-lg lg:mt-6 lg:text-lg">
                    Connecting the Small-scale Gold Mining Industry to the Global Business Community
                  </p>
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </motion.section>
  );
}
