import cx from 'classnames';
import { forwardRef } from 'react';

function Input({ className, component: Component = 'input', error, ...props }, ref) {
  return (
    <Component
      ref={ref}
      className={cx(
        'min-h-10 rounded-md placeholder-neutral-400 transition duration-150 focus:ring-1 disabled:border-neutral-200 disabled:bg-neutral-100 disabled:text-black',
        {
          'border-neutral-300 focus:border-primary-400 focus:ring-primary-400': !error,
          'border-red-500 focus:border-red-500 focus:ring-red-500': error,
        },
        className
      )}
      {...props}
    />
  );
}

export default forwardRef(Input);
