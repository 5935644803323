import ContactForm from '@/components/ContactForm';
import FancyHeader from '@/components/FancyHeader';
import { fadeInUp } from '@/constants/motions';
import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';

export default function ContactBlock() {
  return (
    <section className="scroll-mt-40 bg-neutral-200 py-16 px-4 lg:scroll-mt-20" id="contact">
      <div className="container-screen">
        <motion.div
          className="grid grid-cols-1 items-center gap-4 lg:grid-cols-2"
          initial="hidden"
          transition={{
            ...fadeInUp.transition,
            when: 'beforeChildren',
            staggerChildren: 0.4,
          }}
          variants={fadeInUp}
          viewport={fadeInUp.viewport}
          whileInView="visible"
        >
          <motion.div className="flex flex-col gap-4" variants={fadeInUp}>
            <FancyHeader Subtitle="Contact" title="Contact Us" />
            <p className="text-neutral-600">
              We&apos;re looking forward to doing business with you!
            </p>
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-2">
                <Icon className="text-primary-400" height="24" icon="fluent:mail-16-filled" />
                <span>info@surinamenaturalstoneco.com</span>
              </div>
              <div className="flex items-start gap-2">
                <Icon
                  className="flex-shrink-0 text-primary-400"
                  height="24"
                  icon="fluent:call-16-filled"
                />
                <span className="leading-snug">+597520112 | +597520488 | +597426988</span>
              </div>
              <div className="flex items-center gap-2">
                <Icon className="text-primary-400" height="24" icon="ant-design:mobile-filled" />
                <span>+5978229898 | +5978839591</span>
              </div>
            </div>
          </motion.div>
          <motion.div className="rounded-xl bg-white p-6 shadow-lg" variants={fadeInUp}>
            <ContactForm />
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
}
