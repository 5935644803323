import { API_BASE_URL } from '@/constants/env';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

export function useContactForm() {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState();

  const captchaRef = useRef(null);
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const onCaptchaExpire = () => {
    return toast.error('Captcha token has expired');
  };

  const onCaptchaError = (error) => {
    return toast.error(`Captcha error: ${error}`);
  };

  const onCaptchaVerify = (token) => {
    if (token) setCaptchaVerified(true);
  };

  const onSubmit = handleSubmit(async (formData) => {
    // Check captcha
    if (!captchaVerified) return toast.error("Please proof that you're human");

    // Reset loading state
    setLoading(true);

    // Send API request
    try {
      const response = await fetch(API_BASE_URL.concat('/contact'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.error) {
        toast.error(data.error.message);
      } else if (data.success) {
        reset();
        toast.success('Thank you for contacting us');
      }
    } catch (ex) {
      toast.error(ex.message);
    } finally {
      setLoading(false);
      setCaptchaVerified(false);
      captchaRef.current?.resetCaptcha();
    }
  });

  return {
    register,
    errors,
    loading,
    onSubmit,
    captchaRef,
    onCaptchaExpire,
    onCaptchaError,
    onCaptchaVerify,
  };
}
