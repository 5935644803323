import FancyHeader from '@/components/FancyHeader';
import MissionVisionCard from '@/components/MissionVisionCard';
import { fadeInUp } from '@/constants/motions';
import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';

export default function MissionVisionBLock() {
  return (
    <section className="bg-neutral-100 px-4 pt-8 md:pt-16">
      <div className="container-screen">
        <motion.div
          className="grid grid-rows-1 gap-4 lg:grid-flow-row-dense lg:grid-cols-3 lg:gap-8"
          initial="hidden"
          transition={{
            ...fadeInUp.transition,
            when: 'beforeChildren',
            staggerChildren: 0.4,
          }}
          variants={fadeInUp}
          viewport={fadeInUp.viewport}
          whileInView="visible"
        >
          <div className="flex self-center">
            <motion.div variants={fadeInUp}>
              <FancyHeader Subtitle="About our" title="Mission &amp; vision" />
            </motion.div>
          </div>
          <div className="grid grid-rows-1 gap-4 md:grid-cols-2 lg:col-span-2">
            <motion.div variants={fadeInUp}>
              <MissionVisionCard
                description="A professional, stable gold company operating in a constantly changing environment providing customized services resulting in ultimate satisfactions for all stakeholders"
                icon={
                  <Icon
                    className="text-neutral-700"
                    height="52"
                    icon="fluent:target-arrow-16-regular"
                  />
                }
                title="Mission"
              />
            </motion.div>
            <motion.div variants={fadeInUp}>
              <MissionVisionCard
                description="To become the prime business partner of our customers, whereby we combine passion, expertise, and experience. Our unique points competencies are listening to our customers and acting pro-actively. By looking forward and assessing diagnoses on a timely manner, we provide a true comprehensive solution."
                icon={
                  <Icon className="text-neutral-700" height="52" icon="fluent:eye-16-regular" />
                }
                title="Vision"
              />
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
}
