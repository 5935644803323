import BlogCard from '@/components/BlogCard';
import FancyHeader from '@/components/FancyHeader';
import { MEDIA_URL } from '@/constants/env';
import { fadeInUp } from '@/constants/motions';
import { motion } from 'framer-motion';

export default function FeaturedBlogsSliderBlock({ items }) {
  return (
    <motion.section
      className="my-8 scroll-mt-32 px-4 md:my-12 lg:scroll-mt-20"
      id="blog"
      initial="hidden"
      transition={{
        ...fadeInUp.transition,
        when: 'beforeChildren',
        staggerChildren: 0.4,
      }}
      variants={fadeInUp}
      viewport={fadeInUp.viewport}
      whileInView="visible"
    >
      <div className="container-screen">
        <motion.div className="mb-8 flex flex-col gap-4" variants={fadeInUp}>
          <FancyHeader Subtitle="blogs" title="Latest News" />
          <p className="text-neutral-600">
            Read our latest blogs to find out more about our social projects!
          </p>
        </motion.div>
        <div className="grid grid-rows-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:gap-12">
          {items.map(({ title, date, slug, image, excerpt }, i) => (
            <BlogCard
              key={i}
              date={date}
              excerpt={excerpt}
              image={MEDIA_URL.concat(image)}
              title={title}
              url={`/blog/${slug}`}
            />
          ))}
        </div>
      </div>
    </motion.section>
  );
}
