import AboutBlock from '@/blocks/AboutBlock';
import ContactBlock from '@/blocks/ContactBlock';
import CoreValuesBlock from '@/blocks/CoreValuesBlock';
import FeaturedBlogsSliderBlock from '@/blocks/FeaturedBlogsSliderBlock';
import HomeSliderBlock from '@/blocks/HomeSliderBlock';
import MissionVisionBlock from '@/blocks/MissionVisionBlock';
import ServiceBlock from '@/blocks/ServiceBlock';
import ShortAboutBlock from '@/blocks/ShortAboutBlock';
import StatisticsBlock from '@/blocks/StatisticsBlock';
import FullWidthLayout from '@/layouts/FullWidthLayout';
import { PostProvider } from '@/utils/post';

export default function HomePage({ blog }) {
  return (
    <FullWidthLayout>
      <HomeSliderBlock />
      <ShortAboutBlock />
      <AboutBlock />
      <MissionVisionBlock />
      <CoreValuesBlock />
      <ServiceBlock />
      <StatisticsBlock />
      <FeaturedBlogsSliderBlock items={blog} />
      <ContactBlock />
    </FullWidthLayout>
  );
}

export async function getStaticProps() {
  const blogProvider = new PostProvider('blog');
  const blog = blogProvider.getAll(['title', 'date', 'slug', 'image', 'excerpt']);

  return {
    props: {
      blog,
    },
  };
}
