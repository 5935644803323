import FancyHeader from '@/components/FancyHeader';
import { MEDIA_URL } from '@/constants/env';
import { fadeInUp } from '@/constants/motions';
import Image from '@/ui/Image';
import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';

export default function AboutBlock() {
  return (
    <section className="bg-neutral-100 px-4 pt-8 md:pt-16">
      <div className="container-screen relative">
        <motion.div
          className="flex flex-col gap-2 md:grid md:gap-8 lg:items-center xl:grid-cols-2 xl:gap-16"
          initial="hidden"
          transition={{
            ...fadeInUp.transition,
          }}
          variants={fadeInUp}
          viewport={fadeInUp.viewport}
          whileInView="visible"
        >
          <div className="relative lg:order-1 xl:w-11/12">
            <Image
              className="aspect-video overflow-hidden rounded-lg object-cover"
              src={MEDIA_URL.concat('/about.png')}
            />
            <div className="absolute bottom-0 right-0 mr-4 mb-4 hidden items-center lg:inset-y-0 lg:-bottom-0 lg:left-auto lg:-mr-4 xl:-mr-20 xl:flex">
              <Image
                className="h-20 w-36 overflow-hidden rounded-lg object-cover md:h-32 md:w-44 lg:h-40 lg:w-64"
                src={MEDIA_URL.concat('/about-overflow.png')}
              />
            </div>
          </div>
          <div className="flex flex-col gap-4 lg:order-2">
            <FancyHeader Subtitle="About" title="What We Are Doing" />
            <article className="prose max-w-full">
              <p className="text-neutral-600">
                Suriname Natural Stone Co is the perfect partner for every buyer of gold from the
                small-scale gold miners in Suriname and the buyers of gold on the global market.
                Combining passion, expertise, and experience, we are listening to our customers and
                acting pro-actively, building mutual trust.
              </p>
              <p>
                By looking forward, assessing timely diagnoses, and anticipating, we provide
                comprehensive solutions and thus create long-term engagement with our relations. As
                we are operating under the supervision of the Central Bank in Suriname and other
                controlling authorities, our operations are 100% in compliance with local and
                international legislation.
              </p>
              <p>
                We are proud to be a legitimized company that is operating with trust, creating
                opportunities for the industry, and protecting the public interest.
              </p>
              <p>
                The small-scale gold mining industry in Suriname will keep on growing and is
                currently one of the biggest industries in the local production sector. We are the
                perfect partner for interested parties to buy or sell gold and we’ll keep on
                providing innovative solutions to help stakeholders.
              </p>
            </article>
          </div>
          <div className="absolute right-0 top-0">
            <Icon
              className="h-12 w-12 rotate-6 text-primary-300/60"
              icon="ant-design:gold-filled"
            />
          </div>
        </motion.div>
      </div>
    </section>
  );
}
