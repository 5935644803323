import FancyHeader from '@/components/FancyHeader';
import { fadeInUp } from '@/constants/motions';
import ButtonLink from '@/ui/ButtonLink';
import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';

export default function ShortAboutBLock() {
  return (
    <section className="my-8 scroll-mt-20 px-4 md:my-16 lg:scroll-mt-24" id="about">
      <div className="container-screen">
        <div className="flex flex-col gap-12">
          <motion.div
            className="flex flex-col gap-2 md:gap-4 lg:grid lg:grid-cols-2 lg:items-center lg:gap-12"
            initial="hidden"
            transition={{
              ...fadeInUp.transition,
              when: 'beforeChildren',
              staggerChildren: 0.4,
            }}
            variants={fadeInUp}
            viewport={fadeInUp.viewport}
            whileInView="visible"
          >
            <FancyHeader Subtitle="Welcome to" title="Suriname Natural Stone CO." />
            <motion.div
              className="flex flex-initial flex-col items-start gap-4"
              variants={fadeInUp}
            >
              <article className="prose">
                <p>
                  <strong>
                    Suriname Natural Stone Co is a company operating under supervision of Central
                    Bank in Suriname and other governmental institutions. Our main goal is to help
                    develop the small-scale gold mining industry to it&apos;s fullest potential.
                    With a legal &apos;gold buying license&apos; we buy gold from the gold buying
                    partners from to whom the small-scale miners sell their gold.
                  </strong>
                </p>
              </article>
              <ButtonLink color="primary" href="/about" size="xs">
                READ MORE <Icon height="14" icon="fluent:chevron-right-16-regular" />
              </ButtonLink>
            </motion.div>
          </motion.div>
          {/* <article className="prose max-w-full columns-1 gap-8 sm:columns-2">
            <p>
              For centuries, the mining industry has been the prime sector to drive the economic
              activity in Suriname. As the Bauxite industry has practically come to an end in 2015,
              the only remaining mining industries are Gold and Oil.
            </p>
            <p>
              The Gold Mining industry consists of some large (multinational) mining companies, but
              also of so called small-scaled individual miners. The small-scale gold mining sector
              faces many challenges, and the main challenge is for miners to safely sell the gold.
              Usually these miners collaborate with local gold buying partners who later resell the
              gold.
            </p>
            <p>
              Although this sector is facing lots of challenges, it is crucial to the local economy
              as hundreds of individuals are employed in the small-scale gold mining industry and
              combined, the small-scale gold mining industry is partially driving the economy in
              Suriname.
            </p>
          </article> */}
        </div>
      </div>
    </section>
  );
}
