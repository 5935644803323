import ServiceCard from '@/components/ServiceCard';
import { fadeInUp } from '@/constants/motions';
import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';

export default function ServiceBlock() {
  return (
    <motion.section
      className="my-8 bg-primary-300 px-4 md:my-12 lg:bg-white"
      initial="hidden"
      transition={{
        ...fadeInUp.transition,
      }}
      variants={fadeInUp}
      viewport={fadeInUp.viewport}
      whileInView="visible"
    >
      <div className="mx-auto rounded-lg bg-primary-300 lg:max-w-[1380px] lg:px-8">
        <div className="container-screen relative py-8 md:py-12">
          <div className="relative z-20 flex flex-col items-start gap-4 md:gap-12">
            <div className="flex flex-col items-start gap-2 md:gap-4 lg:grid lg:grid-cols-2 lg:items-center lg:gap-0">
              <div className="flex flex-grow flex-col md:gap-2">
                <span className="font-semibold italic">From</span>
                <h2 className="text-xl font-semibold md:text-2xl lg:text-4xl lg:font-bold">
                  Mining to Exporting
                </h2>
              </div>
            </div>
            <div className="grid gap-8 md:grid-cols-4">
              <ServiceCard service="1" text="Small-scale gold miners are being identified" />
              <ServiceCard
                service="2"
                text="Suriname Natural Stone Co buys gold from buying partners of small-scale miners"
              />
              <ServiceCard
                service="3"
                text="Suriname Natural Stone Co sells gold to buyers on international market "
              />
              <ServiceCard service="4" text="Revenue flows back to community" />
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 z-10 ml-32 mb-36">
            <Icon
              className="scale-[20] text-white/30 md:scale-[25]"
              icon="ant-design:gold-filled"
            />
          </div>
        </div>
      </div>
    </motion.section>
  );
}
