import { useIntersectionObserver } from '@/hooks/useIntersectionObserver';
import CountUp from 'react-countup';

export default function StatisticsBlock() {
  const stats = [
    {
      quantity: 5,
      postfix: '+',
      text: 'International gold buying partners',
    },
    {
      quantity: 10,
      postfix: '+',
      text: 'Local partnerships with buyers of gold from the small-scale gold miners',
    },
    {
      quantity: 15,
      postfix: '+',
      text: 'Local charity projects on a yearly basis',
    },
    {
      quantity: 100,
      postfix: '%',
      text: 'Compliance with local and international legislation',
    },
  ];

  return (
    <section className="custom-img relative my-8 bg-cover bg-fixed bg-center px-4 py-8 md:my-16 md:py-16">
      <div className="container-screen">
        <div className="relative z-20 flex flex-col">
          <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
            {stats.map((stat, i) => (
              <StatisticBox {...stat} key={i} />
            ))}
          </div>
        </div>
      </div>
      <div className="absolute inset-0 z-10 bg-black/60"></div>
    </section>
  );
}

function StatisticBox({ quantity, postfix, text }) {
  const { elemRef, visible } = useIntersectionObserver({ rootMargin: '-90px' });

  return (
    <div ref={elemRef} className="flex flex-col items-center gap-2 md:gap-4">
      <h2 className="text-4xl text-white lg:text-6xl">
        {visible ? <CountUp end={quantity} /> : null}
        <span className="text-primary-400">{postfix}</span>
      </h2>
      <p className="text-center text-white">{text}</p>
    </div>
  );
}
