import { CAPTCHA_SITE_KEY } from '@/constants/env';
import { useContactForm } from '@/hooks/contact';
import Button from '@/ui/Button';
import FormControl from '@/ui/FormControl';
import FormErrorMessage from '@/ui/FormErrorMessage';
import FormLabel from '@/ui/FormLabel';
import Input from '@/ui/Input';
import HCaptcha from '@hcaptcha/react-hcaptcha';

export default function ContactForm() {
  const {
    register,
    errors,
    loading,
    onSubmit,
    captchaRef,
    onCaptchaExpire,
    onCaptchaError,
    onCaptchaVerify,
  } = useContactForm();

  return (
    <form className="flex flex-col gap-4" onSubmit={onSubmit}>
      <FormControl>
        <FormLabel isRequired htmlFor="name">
          Full Name
        </FormLabel>
        <Input
          id="name"
          type="text"
          {...register('name', {
            required: 'This field is required',
          })}
        />
        <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
      </FormControl>
      <FormControl>
        <FormLabel isRequired htmlFor="email">
          Email
        </FormLabel>
        <Input
          id="email"
          type="email"
          {...register('email', {
            required: 'This field is required',
          })}
        />
        <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="phone">Phone</FormLabel>
        <Input id="phone" type="text" {...register('phone')} />
        <FormErrorMessage>{errors.phone && errors.phone.message}</FormErrorMessage>
      </FormControl>
      <FormControl>
        <FormLabel isRequired htmlFor="subject">
          Subject
        </FormLabel>
        <Input
          id="subject"
          type="text"
          {...register('subject', {
            required: 'This field is required',
          })}
        />
        <FormErrorMessage>{errors.subject && errors.subject.message}</FormErrorMessage>
      </FormControl>
      <FormControl>
        <FormLabel isRequired htmlFor="message">
          Message
        </FormLabel>
        <Input
          component="textarea"
          id="message"
          rows="4"
          {...register('message', {
            required: 'This field is required',
          })}
        />
        <FormErrorMessage>{errors.message && errors.message.message}</FormErrorMessage>
      </FormControl>
      <HCaptcha
        ref={captchaRef}
        sitekey={CAPTCHA_SITE_KEY}
        onError={onCaptchaError}
        onExpire={onCaptchaExpire}
        onVerify={onCaptchaVerify}
      />
      {loading ? (
        <span className="cursor-progress self-start rounded-lg border border-transparent bg-neutral-200 py-2 px-8 text-neutral-400">
          Sending...
        </span>
      ) : (
        <Button className="self-start px-8" color="primary" type="submit">
          Send
        </Button>
      )}
    </form>
  );
}
