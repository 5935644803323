export default function MissionVisionCard({ icon, title, description }) {
  return (
    <div className="flex h-full flex-col gap-4 rounded-lg bg-gradient-to-b from-primary-300 to-primary-300/60 px-4 py-8 xl:p-8">
      <div className="flex flex-col items-center gap-2">
        {icon}
        <h3 className="text-lg font-bold lg:text-2xl">{title}</h3>
      </div>
      <p className="text-center">{description}</p>
    </div>
  );
}
