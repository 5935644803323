import { fadeInUp } from '@/constants/motions';
import Image from '@/ui/Image';
import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import Link from 'next/link';

export default function BlogCard({ date, image, title, excerpt, url }) {
  return (
    <motion.div className="flex flex-col gap-4 overflow-hidden" variants={fadeInUp}>
      <Image
        alt=""
        className="h-48 w-full object-cover px-2 transition duration-300 ease-in hover:scale-105 md:h-56"
        src={image}
      />
      <div className="relative z-20 mx-2 -mt-8 flex flex-1 flex-col gap-4 rounded-t-xl bg-gray-200 p-4">
        <div className="flex items-center gap-1">
          <Icon height="24" icon="fluent:calendar-ltr-16-regular" />
          {new Date(date).toDateString()}
        </div>
        <h4 className="font-medium">{title}</h4>
        <p className="leading-snug text-neutral-600 line-clamp-3">{excerpt}</p>
        <div className="mt-auto border-t-2 border-neutral-300 pt-4">
          <Link href={url}>
            <a className="flex items-center gap-1 text-sm hover:text-primary-400 hover:transition hover:duration-200">
              Read more <Icon height="14" icon="fluent:chevron-double-right-16-regular" />
            </a>
          </Link>
        </div>
      </div>
    </motion.div>
  );
}
