export default function ServiceCard({ service, text }) {
  return (
    <div className="flex flex-col items-start gap-2">
      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-black/40">
        <span className="text-lg text-white">{service}</span>
      </div>
      <p>{text}</p>
    </div>
  );
}
