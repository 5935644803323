import FancyHeader from '@/components/FancyHeader';
import { MEDIA_URL } from '@/constants/env';
import { fadeInUp } from '@/constants/motions';
import Image from '@/ui/Image';
import { motion } from 'framer-motion';

export default function CoreValuesBlock() {
  const values = [
    {
      value: 'Customer Satisfaction:',
      description: 'We keep our promises and commitments',
    },
    {
      value: 'Continuous Growth:',
      description: 'We are focused on continuous improvement, and we are result-oriented',
    },
    {
      value: 'Partnership:',
      description: 'Operate with a partnership mentality',
    },
    {
      value: 'Integrity:',
      description: 'We treat everyone with respect and set a good example!',
    },
  ];
  return (
    <section className="bg-neutral-100 py-8 px-4 md:py-16">
      <div className="container-screen">
        <div className="grid grid-rows-1 gap-4">
          <motion.div
            className="relative grid gap-2 md:grid-cols-2 md:items-center md:gap-4 lg:gap-10 "
            initial="hidden"
            transition={{
              ...fadeInUp.transition,
            }}
            variants={fadeInUp}
            viewport={fadeInUp.viewport}
            whileInView="visible"
          >
            <div className="relative lg:order-1">
              <Image
                className="aspect-video h-40 w-full overflow-hidden rounded-lg object-cover md:h-80"
                src={MEDIA_URL.concat('/core-value.png')}
              />
            </div>
            <div className="flex flex-col gap-4 lg:order-2">
              <FancyHeader Subtitle="About our" title="Core Values" />
              <div className="flex flex-grow flex-col gap-2">
                {values.map((value, i) => (
                  <div key={i} className="flex items-start gap-2">
                    <Image
                      className="h-4 w-4 flex-shrink-0 object-contain"
                      src={MEDIA_URL.concat('/gold-block.svg')}
                    />
                    <p className="font-bold leading-snug text-neutral-600">
                      {value.value}
                      <span className="font-normal"> {value.description}</span>
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
