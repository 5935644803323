import { useState } from 'react';
import { useIntersectionObserverRef } from 'rooks';

export const useIntersectionObserver = ({ options }) => {
  const [visible, setVisible] = useState(false);

  const [elemRef] = useIntersectionObserverRef((entries) => {
    if (entries && entries[0]) {
      if (entries[0].isIntersecting) {
        setVisible(true);
      }
    }
  }, options);

  return { elemRef, visible };
};
