import Link from 'next/link';
import Button from './Button';

export default function ButtonLink({ children, href, ...props }) {
  return (
    <Link passHref href={href}>
      <Button component="a" {...props}>
        {children}
      </Button>
    </Link>
  );
}
